import React from "react";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import ListTile from "./ticket_list_page_component/listTile";
import DateTile from "./ticket_list_page_component/dateTile";
// import * as workpalJS from "@workpal-build/workpaljs"; //comment out this for future use
import { motion } from "framer-motion";

function TicketListPage({ print }) {
  // Redux as state management
  const state = useSelector((state) => {
    return state;
  });

  //Grouping the data according to date
  const group = (arr, property) => {
    arr.forEach((item) => {
      const date = new Date(item.createdDate);
      const year = date.getFullYear();
      const month = date.toLocaleString("default", { month: "short" });
      item.createdDate = month + " " + year;
    });
    return arr.reduce((accVal, currVal) => {
      var key = currVal[property];
      if (!accVal[key]) {
        accVal[key] = [];
      }
      accVal[key].push(currVal);
      return accVal;
    }, {});
  };
  const grouped = group(state.ticket.history, "createdDate");

  //MUI Styling
  // const styles = {
  //   buttonContainer: {
  //     background: "white",
  //     height: "100px",
  //     width: "100vw",
  //     position: "fixed",
  //     bottom: 0,
  //     padding: "16px",
  //   },
  //   bannerContainer: {
  //     position: "relative",
  //     display: "flex",
  //     justifyContent: "center",
  //   },
  //   pageTitle: {
  //     position: "absolute",
  //     top: 20,
  //   },
  // };

  // useEffect(() => {
  //   const newArr = state.testing.filter((arrItem) => {
  //     return arrItem.toLocaleLowerCase().includes(searchField);
  //   });
  //   setFilteredState(newArr);
  // }, [state.testing, searchField]);

  return (
    <motion.div
      sx={{ height: "100vh" }}
      initial={{ x: "0%" }}
      animate={{ width: "100%" }}
      exit={{ x: -window.innerWidth, transition: { duration: 0.2 } }}
    >
      <Stack>
        {/* This is the list UI */}
        <Box sx={{ paddingBottom: "100px" }}>
          {/* <Button onClick={workpalJS.openExternalBrowser("https://yahoo.com")}>
            hello
          </Button> */}
          {Object.keys(grouped).map((date, i) => {
            return (
              <div key={i}>
                <DateTile date={date} />
                {grouped[date].map((eachItem) => {
                  return (
                    <ListTile
                      key={eachItem.id}
                      ticketNumber={eachItem.id}
                      status={eachItem.status}
                      category={eachItem.category}
                      issue={eachItem.issue}
                    />
                  );
                })}
              </div>
            );
          })}
        </Box>
        {/* This is the button UI */}
        {/* <Box sx={styles.buttonContainer}>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            href="/report_incident_form"
          >
            Report Incident
          </Button>
        </Box> */}
      </Stack>
    </motion.div>
  );
}

export default TicketListPage;
