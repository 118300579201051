import React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Divider,
  stepLabelClasses,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import NoLongerFaceIssueButton from "../no_longer_face_issue/no_longer_face_issue_button.component";
import ResolutionDetails from "../resolution_details/resolution_details";
import CustomChip from "../../components/custom_chip.component";

function IncidentDetail({ details }) {
  const {
    status,
    item,
    description,
    issue,
    category,
    topic,
    // isResolved, //commenting this out for future use
    resolutionDetails,
    id,
  } = details;
  const steps = [
    "Submitted",
    "In Progress",
    "Pending Confirmation",
    "Resolved",
  ];
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    switch (status) {
      case "Submitted":
        return setActiveStep(0);
      case "Pending user's response":
        return setActiveStep(1);
      case "In progress":
        return setActiveStep(1);
      case "Pending confirmation":
        return setActiveStep(2);
      case "resolved":
        return setActiveStep(3);
      default:
        return setActiveStep(-1);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // custom UI for status bar
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    //status bar connector color
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 10px)",
      right: "calc(50% + 10px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#D0D0D0",
      },
    },
    // [`&.${stepConnectorClasses.completed}`]: {
    //   [`& .${stepConnectorClasses.line}`]: {
    //     borderColor: "#376EEE",
    //   },
    // },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#D0D0D0",
      borderTopWidth: 2,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    // status bar icon
    color: "#d0d0d0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#d0d0d0",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#376EEE",
      width: 12,
      height: 12,
      borderRadius: "50%",
      backgroundColor: "#376EEE",
    },
    "& .QontoStepIcon-circle": {
      width: 12,
      height: 12,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));
  const QontoStepIcon = (props) => {
    const {
      active,
      // completed,
      className,
    } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {active ? (
          <div className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  };
  const ColorlibStepLabel = styled(StepLabel)(() => ({
    // status bar label
    [`& .${stepLabelClasses.label}`]: {
      // [`&.${stepLabelClasses.completed}`]: {
      //   fontSize: "10px",
      // },
      [`&.${stepLabelClasses.active}`]: {
        fontSize: "10px",
        fontWeight: "400",
      },

      color: "#4D4D4D",
      fontSize: "10px",
      // position: "absolute",
      // padding: "0px",
      // margin: "0px",
      // top: "30px",
      // display: " table-cell",
      // verticalAlign: "middle",
    },
  }));

  // displaying different resolution component depending on the status
  const displayResolutionDetails = (status, resolutionDetails) => {
    switch (status) {
      case "Submitted":
        return <NoLongerFaceIssueButton />;
      case "Pending user's response":
        return <NoLongerFaceIssueButton />;
      case "In progress":
        return resolutionDetails ? (
          <ResolutionDetails
            resolutionDetails={resolutionDetails}
            status={status}
          />
        ) : (
          <div style={{ paddingBottom: "100px" }}></div>
        );
      case "Pending confirmation":
        return (
          <ResolutionDetails
            resolutionDetails={resolutionDetails}
            status={status}
          />
        );
      case "resolved":
        return (
          <ResolutionDetails
            resolutionDetails={resolutionDetails}
            status={status}
          />
        );
      default:
        return <p> </p>;
    }
  };

  const styles = {
    ticketNumberStyle: {
      color: "#4D4D4D",
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 600,
      letterSpacing: "0.15px",
      marginTop: "16px",
      marginBottom: "16px",
    },
    greyContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
  };

  return (
    <Box sx={{ padding: "0px" }}>
      <Box sx={{ padding: "16px", backgroundColor: "#F8F8F8" }}>
        <Box sx={styles.greyContainer}>
          <CustomChip status={status} />

          {/* {status === "Pending user's response" ? (
            <Link to={`/chat_page`} style={{ textDecoration: "none" }}>
              <img src={require("../img/../../activityIcon.png")} />
            </Link>
          ) : (
            ""
          )} */}
        </Box>
        <h6 style={styles.ticketNumberStyle}>{`INC000000${id}`}</h6>
        <Box sx={{ background: "white", borderRadius: "8px", padding: "16px" }}>
          <Stepper
            nonLinear
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <ColorlibStepLabel StepIconComponent={QontoStepIcon}>
                  {label}
                </ColorlibStepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>

      <Box
        sx={{
          paddingBottom:
            status === "Submitted" || status === "Pending user's response"
              ? "100px"
              : "20px",
        }}
      >
        <Box sx={{ padding: "16px" }}>
          <p className="pLabel">Device experiencing the issue</p>
          <h6 className="detailContent">{item}</h6>
          <p className="pLabel">Topic</p>
          <h6 className="detailContent">{topic}</h6>
          <p className="pLabel">Category</p>
          <h6 className="detailContent">{category}</h6>
          <p className="pLabel">Issue</p>
          <h6 className="detailContent">{issue}</h6>
          <p className="pLabel">Description</p>
          <h6 className="detailContent">{description}</h6>
        </Box>
        <Divider />
      </Box>

      {displayResolutionDetails(status, resolutionDetails)}
    </Box>
  );
}

export default IncidentDetail;
